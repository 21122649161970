import { apiSlice } from "../api/apiSlice";

export const notificationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    allNotificationFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/alert-list",
        method: "POST",
        body: data,
      }),
    }),
    getLatestNotification: builder.query({
      query: () => "/apiV2/latest-alert-list",
    }),
  }),
});

export const {
  useAllNotificationFetchMutation,
  useGetLatestNotificationQuery,
} = notificationApi;
